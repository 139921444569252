<script>
    import { canvas, camera, scene, terrain } from '../stores/renderer';
    import * as THREE from 'three';
    import { tileSize } from '../stores/constants';
    import { tileData, width, depth } from '../stores/engine';
    import { onDestroy, onMount } from 'svelte';

    export let texture_id = 0;
    export let texture_index = 0;
    export let overlay_id = 0;
    export let overlay_index = 0;
    export let side_id = 0;
    export let side_overlay_id = 0;

    const texture_ids = [
        'none',
        'pave',
        'grass',
        'road',
        'dirt'
    ];
    const overlay_ids = [
        'none',
        'edge',
        'shore',
        'road_edge'
    ];
    const modes = [
        'none',
        'draw',
        'eyedropper'
    ];
    let mode = 0;

    const tile2num = (a, b, c, d, e, f) => {
        a = Math.max(0, Math.min(15, Math.round(a))); // texture_id
        b = Math.max(0, Math.min(15, Math.round(b))); // texture_index
        c = Math.max(0, Math.min(15, Math.round(c))); // overlay_id
        d = Math.max(0, Math.min(15, Math.round(d))); // overlay_index
        e = Math.max(0, Math.min(15, Math.round(e))); // side_id
        f = Math.max(0, Math.min(15, Math.round(f))); // side_overlay_id

        return (a << 20) | (b << 16) | (c << 12) | (d << 8) | (e << 4) | f;
    };

    const num2rgba = num => {
        return {
            r: (num >> 16) & 0xff,
            g: (num >> 8) & 0xff,
            b: num & 0xff,
            a: (num >> 24) & 0xff
        };
    };

    const handleSubmit = e => {
        e.preventDefault();

        const blob = new Blob([$tileData], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'tile_data.bin';
        a.click();
        URL.revokeObjectURL(url);

    };

    $: num = tile2num(texture_id, texture_index, overlay_id, overlay_index, side_id, side_overlay_id);
    $: rgba = num2rgba(num);

    const cursor = {
        x: 0,
        y: 0,
        z: 0
    };
    const cursorMesh = new THREE.Mesh(
        new THREE.BoxGeometry(tileSize, tileSize, tileSize),
        new THREE.MeshBasicMaterial({ color: 0xff0000, transparent: true, opacity: 0.5 })
    );
    $: cursorMesh.visible = mode > 0;
    $: cursorMesh.position.set(
        (cursor.x * tileSize + tileSize / 2) - $width * tileSize / 2,
        cursor.y * tileSize + tileSize / 2, 
        (cursor.z * tileSize + tileSize / 2) - $depth * tileSize / 2
    );

    const raycaster = new THREE.Raycaster();
    let terrainGeometry;

    const mouseMoveHandler = e => {
        if(!terrainGeometry) return;
        if(!cursorMesh.visible) return;

        const rect = $canvas.getBoundingClientRect();
        const x = (e.clientX - rect.left) / rect.width * 2 - 1;
        const y = -(e.clientY - rect.top) / rect.height * 2 + 1;

        raycaster.setFromCamera({ x, y }, $camera);
        const intersects = raycaster.intersectObject(terrainGeometry);

        if(intersects.length > 0) {
            const { x, y, z } = intersects[0].point;
            cursor.x = Math.floor(x / tileSize) + $width / 2;
            cursor.y = Math.round((y*2) / tileSize) / 2;
            cursor.z = Math.floor(z / tileSize) + $depth / 2;
        }
    };

    const mouseDownHandler = e => {
        if(!$tileData) return;
        if(!cursorMesh.visible) return;
        if(e.button !== 0) return;

        const i = cursor.z * $width + cursor.x;
        if(mode === 1) {
            $tileData[i] = num;
            $terrain.parameters.tileData = $tileData;
            $terrain.update($terrain);
        } else if(mode === 2) {
            const num = $tileData[i];

            texture_id = (num >> 20) & 0xf;
            texture_index = (num >> 16) & 0xf;
            overlay_id = (num >> 12) & 0xf;
            overlay_index = (num >> 8) & 0xf;
            side_id = (num >> 4) & 0xf;
            side_overlay_id = num & 0xf;
        }
    };

    onMount(() => {
        if(!$terrain) {
            console.error('Painter must be a child of Terrain');
            return;
        }

        $scene.add(cursorMesh);
        terrainGeometry = $terrain.children.find(child => child.name === 'terrain_geometry');
        $canvas.addEventListener('mousemove', mouseMoveHandler);
        $canvas.addEventListener('mousedown', mouseDownHandler);
    });

    onDestroy(() => {
        cursorMesh.geometry.dispose();
        cursorMesh.material.dispose();
        $scene.remove(cursorMesh);
        $canvas.removeEventListener('mousemove', mouseMoveHandler);
        $canvas.removeEventListener('mousedown', mouseDownHandler);
    });

</script>
<style>
    fieldset {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        font-size: 12px;
        background: rgba(127, 127, 127, 0.5);
        backdrop-filter: blur(4px);
        text-shadow: 1px 1px 1px black;
    }
    form {
        display: flex;
        flex-direction: column;
    }

    label, div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1em;
        margin: 0.5em 0;
    }
    label span {
        width: 11em;
    }
    div {
        justify-content: center;
    }
    input, select, div button {
        font: inherit;
        color: inherit;
        text-shadow: 1px 1px 1px black;
        background: none;
        border: 1px solid #000;
        border-radius: 4px;
        padding: 2px 4px;
        background: rgba(0, 0, 0, 0.5);
        width: 11em;
    }
    hr {
        border: none;
        border-top: 1px solid #000;
        width: 100%;
    }
    picture {
        width: 256px;
        height: 256px;
        display: flex;
        flex-wrap: wrap;
        margin: 4px auto;
    }
    picture button {
        width: 64px;
        height: 64px;
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        position: relative;
    }
    picture button span {
        display: none;
    }
    picture button:not(.active) {
        background-color: rgba(0, 0, 0, 0.5);
    }
    picture img {
        width: 256px;
        height: 256px;
        position: absolute;
    }
</style>
<fieldset>
    <legend>Painter</legend>
    <form on:submit={handleSubmit}>
        <label>
            <span>texture</span>
            <select bind:value={texture_id}>
                {#each texture_ids as id}
                    <option value={texture_ids.indexOf(id)}>{id}</option>
                {/each}
            </select>
        </label>
        <label>
            <span>overlay</span>
            <select bind:value={overlay_id}>
                {#each overlay_ids as id}
                    <option value={overlay_ids.indexOf(id)}>{id}</option>
                {/each}
            </select>
        </label>
        <label>
            <span>side_id</span>
            <input type="number" bind:value={side_id} min="0" max="15" />
        </label>
        <label>
            <span>side_overlay_id</span>
            <input type="number" bind:value={side_overlay_id} min="0" max="15" />
        </label>

        <hr />

        {#if texture_id !== 0}
            <picture>
                <img src="assets/terrain/urban/{texture_ids[texture_id]}.png" alt="texture" />

                {#each Array.from({ length: 4 }, (_, i) => i) as y}
                    {#each Array.from({ length: 4 }, (_, i) => i) as x}
                        <button on:click|preventDefault={() => {
                            texture_index = y * 4 + x;
                        }} class:active={texture_index === y * 4 + x}>
                            <span>{y * 4 + x}</span>
                        </button>
                    {/each}
                {/each}
            </picture>

            <hr />
        {/if}

        {#if overlay_id !== 0}
            <picture>
                <img src="assets/terrain/urban/{overlay_ids[overlay_id]}.png" alt="overlay" />

                {#each Array.from({ length: 4 }, (_, i) => i) as y}
                    {#each Array.from({ length: 4 }, (_, i) => i) as x}
                        <button on:click|preventDefault={() => {
                            overlay_index = y * 4 + x;
                        }} class:active={overlay_index === y * 4 + x}>
                            <span>{y * 4 + x}</span>
                        </button>
                    {/each}
                {/each}
            </picture>

            <hr />
        {/if}

        <label>
            <span>mode</span>
            <select bind:value={mode}>
                {#each modes as id}
                    <option value={modes.indexOf(id)}>{id}</option>
                {/each}
            </select>
        </label>

        <hr />

        <div>
            <button type="submit">
                save
            </button>
            <button>
                close
            </button>
        </div>
    </form>
</fieldset>