<script>
    import { onDestroy, onMount } from 'svelte';
    import { scene } from '../stores/renderer';
    import * as THREE from 'three';
    
    onMount(() => {
        $scene = new THREE.Scene();
    });

    onDestroy(() => {
        $scene = null;
    });
</script>

{#if $scene}
    <slot />
{/if}