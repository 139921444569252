<script>
    import { onDestroy, onMount } from 'svelte';
    import { scene, camera, viewWidth, viewHeight } from '../stores/renderer';
    import { tileSize } from '../stores/constants';
    import * as THREE from 'three';
    
    onMount(() => {
        if(!$scene) {
            console.error('MapCamera must be a child of Scene');
            return;
        }

        const d = tileSize * 32;
        $camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0.1, d * d);
        $camera.position.set(d, d, d);
        $camera.lookAt(0, 0, 0);
        $camera.zoom = 1 / tileSize / 4;
        $scene.add($camera);
    });

    onDestroy(() => {
        if($scene)
            $scene.remove($camera);

        $camera = null;
    });

    $: if($camera) {
        const d = tileSize * tileSize / 2;
        $camera.left = -$viewWidth / d;
        $camera.right = $viewWidth / d;
        $camera.top = $viewHeight / d;
        $camera.bottom = -$viewHeight / d;
        $camera.updateProjectionMatrix();
    }
</script>

{#if $camera}
    <slot />
{/if}