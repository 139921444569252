<script>
    import { onDestroy, onMount } from 'svelte';
    import { canvas, scene, camera, renderer, viewWidth, viewHeight, settings, controls, center, zoom } from '../stores/renderer';
    import * as THREE from 'three';

    const antiAlias = settings.antiAlias;
    const pixelRatio = settings.pixelRatio;

    let animate = () => {
        if(!$renderer) return;
        requestAnimationFrame(animate);

        if($controls) $controls.update();
        if($scene && $camera) {
            $center.x = $camera.position.x;
            $center.y = $camera.position.z;
            $zoom = $camera.zoom;
            $renderer.render($scene, $camera);
        }
    };
    
    onMount(() => {
        if(!$canvas) {
            console.error('Renderer must be a child of Canvas');
            return;
        }

        console.log('Renderer mounted');
        $renderer = new THREE.WebGLRenderer({
            canvas: $canvas,
            antialias: $antiAlias,
            logarithmicDepthBuffer: true,
            alpha: true,
        });
        $renderer.shadowMap.enabled = true;
        // hard shadow
        // $renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        $renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        // $renderer.outputColorSpace = THREE.LinearSRGBColorSpace;

        animate();
    });

    onDestroy(() => {
        $renderer.dispose();
        $renderer = null;
    });

    $: if($renderer) $renderer.setSize($viewWidth, $viewHeight);
    $: if($renderer) $renderer.setPixelRatio($pixelRatio);
</script>

{#if $renderer}
    <slot />
{/if}