<script>
    import { onDestroy, onMount } from 'svelte';
    import { scene, settings, lighting, center, zoom, renderer } from '../stores/renderer';
    import * as THREE from 'three';
    import { tileSize } from '../stores/constants';
    import { Sky } from 'three/addons/objects/Sky.js';
    

    let shadowLight, helper;
    onMount(() => {
        if(!$scene) {
            console.error('Grid must be a child of Scene');
            return;
        }

        const group = new THREE.Group();

        shadowLight = new THREE.DirectionalLight(0xffffff, 0.85);
        shadowLight.castShadow = true;
        shadowLight.shadow.mapSize.width = 4096;
        shadowLight.shadow.mapSize.height = 4096;
        shadowLight.shadow.camera.near = 0.1;
        shadowLight.shadow.camera.far = 20000;

        group.add(shadowLight);
        group.add(shadowLight.target);

        helper = new THREE.CameraHelper(shadowLight.shadow.camera);
        // group.add(helper);

        // const fillLight1 = new THREE.DirectionalLight(0xffffff, 0.4);
        // fillLight1.position.set(250, 250, 250);
        // group.add(fillLight1);

        // const fillLight2 = new THREE.DirectionalLight(0xffffff, 0.4);
        // fillLight2.position.set(0, 100, -350,0);
        // group.add(fillLight2);

        const ambientLight = new THREE.AmbientLight(0xffffff, 0.15);
        group.add(ambientLight);

        $lighting = group;
        $scene.add(group);

        const sun = new THREE.Vector3();
        const sky = new Sky();
        sky.scale.setScalar( 10000 );
        $scene.add( sky );

        const skyUniforms = sky.material.uniforms;

        skyUniforms[ 'turbidity' ].value = 45;
        skyUniforms[ 'rayleigh' ].value = 2;
        skyUniforms[ 'mieCoefficient' ].value = 0.005;
        skyUniforms[ 'mieDirectionalG' ].value = 0.8;

        const pmremGenerator = new THREE.PMREMGenerator( $renderer );
        const sceneEnv = new THREE.Scene();

        const parameters = {
            elevation: 60,
            azimuth: -45
        };

        // setInterval(() => {
        //     parameters.elevation+=0.05;
        //     updateSun();
        // }, 1000 / 60);

        let renderTarget;

        const updateSun = () => {
            const phi = THREE.MathUtils.degToRad( 90 - parameters.elevation );
            const theta = THREE.MathUtils.degToRad( parameters.azimuth );

            sun.setFromSphericalCoords( 1, phi, theta );

            sky.material.uniforms[ 'sunPosition' ].value.copy( sun );

            shadowLight.position.copy(sun).multiplyScalar(1000);
            shadowLight.target.position.set(0, 0, 0);
            shadowLight.shadow.camera.updateProjectionMatrix();
            shadowLight.shadow.camera.updateMatrixWorld();

            if ( renderTarget !== undefined ) renderTarget.dispose();

            sceneEnv.add( sky );
            renderTarget = pmremGenerator.fromScene( sceneEnv );
            $scene.add( sky );

            $scene.environment = renderTarget.texture;
            $scene.environmentIntensity = 0.1;
        }

        updateSun();

    });

    onDestroy(() => {
        if($scene)
            $scene.remove($lighting);
        $lighting = null;
    });

    $: if($lighting) {
        // let z = 4 + ((1 / $zoom) * 4);
        // const d = z * tileSize;
        // const d = Math.max(16 * (1 / $zoom), 2048);
        const d = 2048;

        // shadowLight.target.position.set($center.x, 0, $center.y);
        // shadowLight.position.set($center.x - 5120, 7680, $center.y + 2560);
        shadowLight.shadow.camera.left = -d;
        shadowLight.shadow.camera.right = d;
        shadowLight.shadow.camera.top = d;
        shadowLight.shadow.camera.bottom = -d;
        shadowLight.shadow.camera.updateProjectionMatrix();
        shadowLight.shadow.camera.updateMatrixWorld();
        helper.update();
    }

</script>

{#if $lighting}
    <slot />
{/if}