<script>
    import { onDestroy, onMount } from 'svelte';
    import { scene, settings } from '../stores/renderer';
    import { width, depth } from '../stores/engine';
    import { tileSize } from '../stores/constants';
    import * as THREE from 'three';
    
    const showGrid = settings.showGrid;

    let grid;

    const create = () => {
        if(grid){
            if($scene) $scene.remove(grid);
            grid.dispose();
            grid = null;
        }

        const size = Math.max($width, $depth);

        grid = new THREE.GridHelper(size * tileSize, size);
        grid.position.set(size * (tileSize / 2), 0, size * (tileSize / 2));
        $scene.add(grid);
    };

    onMount(() => {
        if(!$scene) {
            console.error('Grid must be a child of Scene');
            return;
        }
    });

    onDestroy(() => {
        if($scene)
            $scene.remove(grid);

        grid = null;
    });

    $: if($scene) create($width, $depth);
    $: if(grid) grid.visible = $showGrid;
</script>

{#if grid}
    <slot />
{/if}