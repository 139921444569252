import '@fortawesome/fontawesome-pro/css/all.css';

import { mount } from 'svelte';
import App from './Svelte/App.svelte';

mount(App, {
    target: document.body,
    props: {
        battlefield: "test",
        players: [
            {
                id: 0,
                src: "zoop",
                position: 0,
                color: "blue"
            }
        ],
        localPlayerId: 0
    }
});