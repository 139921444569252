#define STANDARD
#ifdef PHYSICAL
	#define IOR
	#define USE_SPECULAR
#endif
uniform vec3 diffuse;
uniform vec3 emissive;
uniform float roughness;
uniform float metalness;
uniform float opacity;

uniform sampler2D tileDataTexture;
uniform sampler2D paveTexture;
uniform sampler2D edgeTexture;
uniform sampler2D shoreTexture;
uniform sampler2D roadTexture;
uniform sampler2D roadEdgeTexture;
uniform sampler2D grassTexture;
uniform sampler2D dirtTexture;
uniform float width;
uniform float depth;
uniform float tileSize;
varying vec3 vPosition;
varying vec3 vWorldPosition;


#ifdef IOR
	uniform float ior;
#endif
#ifdef USE_SPECULAR
	uniform float specularIntensity;
	uniform vec3 specularColor;
	#ifdef USE_SPECULAR_COLORMAP
		uniform sampler2D specularColorMap;
	#endif
	#ifdef USE_SPECULAR_INTENSITYMAP
		uniform sampler2D specularIntensityMap;
	#endif
#endif
#ifdef USE_CLEARCOAT
	uniform float clearcoat;
	uniform float clearcoatRoughness;
#endif
#ifdef USE_DISPERSION
	uniform float dispersion;
#endif
#ifdef USE_IRIDESCENCE
	uniform float iridescence;
	uniform float iridescenceIOR;
	uniform float iridescenceThicknessMinimum;
	uniform float iridescenceThicknessMaximum;
#endif
#ifdef USE_SHEEN
	uniform vec3 sheenColor;
	uniform float sheenRoughness;
	#ifdef USE_SHEEN_COLORMAP
		uniform sampler2D sheenColorMap;
	#endif
	#ifdef USE_SHEEN_ROUGHNESSMAP
		uniform sampler2D sheenRoughnessMap;
	#endif
#endif
#ifdef USE_ANISOTROPY
	uniform vec2 anisotropyVector;
	#ifdef USE_ANISOTROPYMAP
		uniform sampler2D anisotropyMap;
	#endif
#endif
varying vec3 vViewPosition;
#include <common>
#include <packing>
#include <dithering_pars_fragment>
#include <color_pars_fragment>
#include <uv_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <alphatest_pars_fragment>
#include <alphahash_pars_fragment>
#include <aomap_pars_fragment>
#include <lightmap_pars_fragment>
#include <emissivemap_pars_fragment>
#include <iridescence_fragment>
#include <cube_uv_reflection_fragment>
#include <envmap_common_pars_fragment>
#include <envmap_physical_pars_fragment>
#include <fog_pars_fragment>
#include <lights_pars_begin>
#include <normal_pars_fragment>
#include <lights_physical_pars_fragment>
#include <transmission_pars_fragment>
#include <shadowmap_pars_fragment>
#include <bumpmap_pars_fragment>
#include <normalmap_pars_fragment>
#include <clearcoat_pars_fragment>
#include <iridescence_pars_fragment>
#include <roughnessmap_pars_fragment>
#include <metalnessmap_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>
void main() {
	vec4 diffuseColor = vec4( diffuse, opacity );
	#include <clipping_planes_fragment>
	ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
	vec3 totalEmissiveRadiance = emissive;
	#include <logdepthbuf_fragment>
	#include <map_fragment>
	#include <color_fragment>



	float x = floor(vPosition.x / tileSize);
	float z = floor(vPosition.z / tileSize);

	float px = mod(vPosition.x, tileSize);
	float pz = mod(vPosition.z, tileSize);


	vec4 tileColor = texture2D( tileDataTexture, vec2( x / width, z / depth) );


	// Extract the packed integer from the RGBA components
	// int num = int(tileColor.r * 255.0) << 16 | int(tileColor.g * 255.0) << 8 | int(tileColor.b * 255.0);

	// Extract individual values from the packed integer
	// int texture_id = (num >> 20) & 15;
	// int overlay_id = (num >> 16) & 15;
	// int overlay_index = (num >> 10) & 63;
	// int overlay_index_mode = (num >> 8) & 3;
	// int side_id = (num >> 4) & 15;
	// int side_overlay_id = num & 15;

	// /*
	// 	texure_id: 0-15 = 4bit
	// 	texture_index: 0-63 = 6bit
	// 	texture_index_mode: 0-3 = 2bit (1, 1/2. 1/4, 1/8)
	// 	overlay_id: 0-15 = 4bit
	// 	overlay_index: 0-63 = 6bit
	// 	overlay_index_mode: 0-3 = 2bit (1, 1/2. 1/4, 1/8)
	// 	side_id: 0-15 = 4bit
	// 	side_overlay_id: 0-15 = 4bit
	// 	= 32bit
	// */

	// int num = int(tileColor.r * 255.0) << 24 | int(tileColor.g * 255.0) << 16 | int(tileColor.b * 255.0) << 8 | int(tileColor.a * 255.0);

	// int texture_id = (num >> 28) & 15;
	// int texture_index = (num >> 22) & 63;
	// int texture_index_mode = (num >> 20) & 3;
	// int overlay_id = (num >> 16) & 15;
	// int overlay_index = (num >> 10) & 63;
	// int overlay_index_mode = (num >> 8) & 3;
	// int side_id = (num >> 4) & 15;
	// int side_overlay_id = num & 15;
	
	/*
		texture_id 0-15, 4 bits
		texture_index 0-15 4 bits
		overlay_id 0-15 4 bits
		overlay_index 0-15 4 bits
		side_id 0-15 4 bits
		side_overlay_id 0-15 4 bits
		= 24 bits
	*/

	int num = int(tileColor.r * 255.0) << 16 | int(tileColor.g * 255.0) << 8 | int(tileColor.b * 255.0);

	int texture_id = (num >> 20) & 15;
	int texture_index = (num >> 16) & 15;
	int overlay_id = (num >> 12) & 15;
	int overlay_index = (num >> 8) & 15;
	int side_id = (num >> 4) & 15;
	int side_overlay_id = num & 15;

	vec2 uv;
	float factor = 4.0;
	float size = 1.0 / factor; 
	vec3 worldNormal = normalize( cross( dFdx( vWorldPosition ), dFdy( vWorldPosition ) ) );

	// only top for now
	if( worldNormal.y > 0.0 ) {

		if( texture_id > 0 || overlay_id > 0 ) {

			// mode
			uv = vec2( px / tileSize, pz / tileSize );
			uv = vec2(uv / factor);

			// index
			float texture_x = mod(float(texture_index), factor);
			float texture_y = floor(float(texture_index) / factor);
			uv += vec2( texture_x * size, texture_y * size );

			// main texture
			if(texture_id == 1){
				diffuseColor = vec4( texture2D( paveTexture, uv ).rgb, 1.0 );
			}
			if(texture_id == 2){
				diffuseColor = vec4( texture2D( grassTexture, uv ).rgb, 1.0 );
			}
			if(texture_id == 3){
				diffuseColor = vec4( texture2D( roadTexture, uv ).rgb, 1.0 );
			}
			if(texture_id == 4){
				diffuseColor = vec4( texture2D( dirtTexture, uv ).rgb, 1.0 );
			}

			// overlay texture

			// mode
			uv = vec2( px / tileSize, pz / tileSize );
			uv = vec2(uv / factor);

			// index
			float overlay_x = mod(float(overlay_index), factor);
			float overlay_y = floor(float(overlay_index) / factor);
			uv += vec2( overlay_x * size, overlay_y * size );

			if(overlay_id == 1){
				vec4 edgeColor = texture2D( edgeTexture, uv );
				diffuseColor = mix(diffuseColor, edgeColor, edgeColor.a);
			}
			if(overlay_id == 2){
				vec4 shoreColor = texture2D( shoreTexture, uv );
				diffuseColor = mix(diffuseColor, shoreColor, shoreColor.a);
			}
			if(overlay_id == 3){
				vec4 roadEdgeColor = texture2D( roadEdgeTexture, uv );
				diffuseColor = mix(diffuseColor, roadEdgeColor, roadEdgeColor.a);
			}
		}
	}

	#include <alphamap_fragment>
	#include <alphatest_fragment>
	#include <alphahash_fragment>
	#include <roughnessmap_fragment>
	#include <metalnessmap_fragment>
	#include <normal_fragment_begin>
	#include <normal_fragment_maps>
	#include <clearcoat_normal_fragment_begin>
	#include <clearcoat_normal_fragment_maps>
	#include <emissivemap_fragment>
	#include <lights_physical_fragment>
	#include <lights_fragment_begin>
	#include <lights_fragment_maps>
	#include <lights_fragment_end>
	#include <aomap_fragment>
	vec3 totalDiffuse = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse;
	vec3 totalSpecular = reflectedLight.directSpecular + reflectedLight.indirectSpecular;
	#include <transmission_fragment>
	vec3 outgoingLight = totalDiffuse + totalSpecular + totalEmissiveRadiance;
	#ifdef USE_SHEEN
		float sheenEnergyComp = 1.0 - 0.157 * max3( material.sheenColor );
		outgoingLight = outgoingLight * sheenEnergyComp + sheenSpecularDirect + sheenSpecularIndirect;
	#endif
	#ifdef USE_CLEARCOAT
		float dotNVcc = saturate( dot( geometryClearcoatNormal, geometryViewDir ) );
		vec3 Fcc = F_Schlick( material.clearcoatF0, material.clearcoatF90, dotNVcc );
		outgoingLight = outgoingLight * ( 1.0 - material.clearcoat * Fcc ) + ( clearcoatSpecularDirect + clearcoatSpecularIndirect ) * material.clearcoat;
	#endif
	#include <opaque_fragment>
	#include <tonemapping_fragment>
	#include <colorspace_fragment>
	#include <fog_fragment>
	#include <premultiplied_alpha_fragment>
	#include <dithering_fragment>
}