<script>
    import Wrapper from './Components/Wrapper.svelte';
    import Renderer from './Renderer/Renderer.svelte';

    import { tileData, width, depth } from './stores/engine';

    $width = 256;
    $depth = 256;
    $: $tileData = new Float32Array($width * $depth * 4);
</script>

<Wrapper>
    <Renderer />
</Wrapper>