<script>
    import { onDestroy, onMount } from 'svelte';
    import { scene, terrain } from '../stores/renderer';
    import { width, depth, tileData } from '../stores/engine';
    import TerrainMesh from './inc/TerrainMesh';

    let loaded = false;
    const create = () => {
        if($terrain) clear();

        $tileData = new Uint32Array(256 * 256);
        fetch('/assets/gltf/bayofpigs_tiles.bin')
            .then(response => response.arrayBuffer())
            .then(buffer => {
                const view = new DataView(buffer);
                for(let i = 0; i < $tileData.length; i++){
                    $tileData[i] = view.getUint32(i * 4, true);
                }
                $terrain = new TerrainMesh($tileData, 256, 256, ()=>{
                    loaded = true;
                });
                $scene.add($terrain);
            })
        ;
    };

    const clear = () => {
        loaded = false;
        if($scene) $scene.remove($terrain);
        // $terrain.dispose();
        $terrain = null;
    };

    onMount(() => {
        if(!$scene) {
            console.error('Terrain must be a child of Scene');
            return;
        }
        create($width, $depth);
    });

    onDestroy(() => {
        clear();
    });

    // $: if($scene && $shapes) create($width, $depth);
</script>

{#if loaded}
    <slot />
{/if}