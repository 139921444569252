<script>
    import { onDestroy, onMount } from 'svelte';
    import { canvas, scene, camera, controls } from '../stores/renderer';
    import { tileSize } from '../stores/constants';
    import * as THREE from 'three';
    import { MapControls } from 'three/addons/controls/MapControls.js';
    
    onMount(() => {
        if(!$camera) {
            console.error('MapCamera must be a child of Camera');
            return;
        }

        $controls = new MapControls($camera, $canvas);
        $controls.enableDamping = true;
        $controls.minZoom = 1 / tileSize / 16;
        $controls.maxZoom = 1 / 8;
        $controls.enablePan = true;
        // $controls.enableRotate = false;
        $controls.enableZoom = true;
        $controls.zoomSpeed = 2;
        $controls.mouseButtons.LEFT = null;
        $controls.mouseButtons.RIGHT = THREE.MOUSE.PAN;

    });

    onDestroy(() => {
        $controls.dispose();
        $controls = null;
    });
</script>

{#if $camera}
    <slot />
{/if}