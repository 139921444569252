import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { tileSize } from '../../stores/constants';
import { LineMaterial } from 'three/addons/lines/LineMaterial.js';
import { LineSegmentsGeometry } from 'three/addons/lines/LineSegmentsGeometry.js';
import { Wireframe } from 'three/addons/lines/Wireframe.js';
import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';

import terrainFragmentShader from './terrainFragmentShader.glsl';
import terrainVertexShader from './terrainVertexShader.glsl';

export default class TerrainMesh extends THREE.Object3D {
    constructor(tileData, width = 0, depth = 0, callback = () => {}) {
        super();

        this.type = 'TerrainMesh';

		this.parameters = {
            tileData,
			width,
			depth
		};

        this.callback = callback;
        this.position.set(-width * tileSize / 2, 0, -depth * tileSize / 2);
        this.loaded = false;
        this.tileDataTexture = null;

        create(this);
        this.update = update.bind(this);
    }
}

const cleanup = geometry => {

    for(let i = 0; i < geometry.attributes.position.array.length; i++){
        const v = geometry.attributes.position.array[i];
        geometry.attributes.position.array[i] = Math.round(v * 4) / 4;
    }
    geometry.deleteAttribute('normal');
    geometry.deleteAttribute('uv');
    geometry = BufferGeometryUtils.mergeVertices(geometry);

    return geometry;
}

const num2rgba = num => {
    return {
        r: (num >> 16) & 0xff,
        g: (num >> 8) & 0xff,
        b: num & 0xff,
        a: (num >> 24) & 0xff
    };
};

const update = (self) => {
    const floatData =  new Float32Array(self.parameters.width * self.parameters.depth * 4);
    for(let i = 0; i < self.parameters.tileData.length; i++){
        const rgba = num2rgba(self.parameters.tileData[i]);
        floatData[i * 4] = rgba.r / 255;
        floatData[i * 4 + 1] = rgba.g / 255;
        floatData[i * 4 + 2] = rgba.b / 255;
        floatData[i * 4 + 3] = rgba.a / 255;
    }

    self.tileDataTexture = new THREE.DataTexture(floatData, self.parameters.width, self.parameters.depth, THREE.RGBAFormat, THREE.FloatType);
    self.tileDataTexture.needsUpdate = true;

    const t = self.children.find(child => child.name === 'terrain_geometry');
    if(t && t.material){
        t.material.dispose();
        t.material = createMaterial(self);
        t.material.needsUpdate = true;
    }
};



const materials = {
    pave: {
        map: new THREE.TextureLoader().load('/assets/terrain/urban/pave.png'),
        // normalMap: new THREE.TextureLoader().load('/assets/terrain/urban/pave_normal.png'),
        // aoMap: new THREE.TextureLoader().load('/assets/terrain/urban/pave_ao.png'),
        // roughnessMap: new THREE.TextureLoader().load('/assets/terrain/urban/pave_roughness.png'),
        // metalnessMap: new THREE.TextureLoader().load('/assets/terrain/urban/pave_metalness.png')
    },
    edge: {
        map: new THREE.TextureLoader().load('/assets/terrain/urban/edge.png')
    },
    shore: {
        map: new THREE.TextureLoader().load('/assets/terrain/urban/shore.png')
    },
    road: {
        map: new THREE.TextureLoader().load('/assets/terrain/urban/road.png')
    },
    road_edge: {
        map: new THREE.TextureLoader().load('/assets/terrain/urban/road_edge.png')
    },
    grass: {
        map: new THREE.TextureLoader().load('/assets/terrain/urban/grass.png')
    },
    dirt: {
        map: new THREE.TextureLoader().load('/assets/terrain/urban/dirt.png')
    },
};

for(const key in materials){
    const m = materials[key];
    m.map.minFilter = THREE.LinearFilter;
    // m.map.magFilter = THREE.LinearFilter;
    // m.map.wrapS = THREE.RepeatWrapping  ;
    // m.map.wrapT = THREE.RepeatWrapping  ;
    m.map.flipY = false;
    m.map.needsUpdate = true;
}

const createMaterial = (self) => {
    const material = new THREE.MeshStandardMaterial({ 
        color: 0xcccccc,
        flatShading: true
    });

    material.onBeforeCompile = (shader) => {
        shader.uniforms.paveTexture = { value: materials.pave.map };
        shader.uniforms.edgeTexture = { value: materials.edge.map };
        shader.uniforms.shoreTexture = { value: materials.shore.map };
        shader.uniforms.roadTexture = { value: materials.road.map };
        shader.uniforms.roadEdgeTexture = { value: materials.road_edge.map };
        shader.uniforms.grassTexture = { value: materials.grass.map };
        shader.uniforms.dirtTexture = { value: materials.dirt.map };

        shader.uniforms.tileDataTexture = { value: self.tileDataTexture };
        shader.uniforms.tileSize = { value: tileSize };
        shader.uniforms.width = { value: self.parameters.width };
        shader.uniforms.depth = { value: self.parameters.depth };
        shader.fragmentShader = terrainFragmentShader;
        shader.vertexShader = terrainVertexShader;
    };

    return material;
};

const create = (self) => {
    update(self);
    const loader = new GLTFLoader();
    loader.load('/assets/gltf/bayofpigs_terrain.gltf', (gltf) => {
        let geometry = gltf.scene.children[0].geometry.clone();
        geometry = cleanup(geometry);
        geometry.scale(tileSize, tileSize, tileSize);
        geometry.computeVertexNormals();
        
        const material = createMaterial(self);

        const mesh = new THREE.Mesh(geometry, material);
        mesh.castShadow = true;
        mesh.receiveShadow = true;
        mesh.name = 'terrain_geometry';
        self.add(mesh);

        const edgeGeometry = new THREE.EdgesGeometry(geometry);
        const lineGeometry = new LineSegmentsGeometry().fromEdgesGeometry(edgeGeometry);
        const lineMaterial = new LineMaterial({
            color: 0x111111,
            linewidth: 2,
            transparent: true,
            opacity: 0.85
        });
        const edges = new Wireframe(lineGeometry, lineMaterial);
        edges.name = 'terrain_edges';
        self.add(edges);

        self.callback();
    });
    loader.load('/assets/gltf/bayofpigs_water.gltf', (gltf) => {
        let geometry = gltf.scene.children[0].geometry.clone();
        geometry = cleanup(geometry);
        geometry.scale(tileSize, tileSize, tileSize);
        geometry.computeVertexNormals();
        const material = new THREE.MeshStandardMaterial({ 
            color: 0x303055,
            flatShading: true,
            transparent: true,
            opacity: 0.75,
            roughness: 0,
            metalness: 0
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.receiveShadow = true;
        self.add(mesh);

        const edgeGeometry = new THREE.EdgesGeometry(geometry);
        const lineGeometry = new LineSegmentsGeometry().fromEdgesGeometry(edgeGeometry);
        const lineMaterial = new LineMaterial({
            color: 0x111111,
            linewidth: 2,
            transparent: true,
            opacity: 0.85
        });
        const edges = new Wireframe(lineGeometry, lineMaterial);
        self.add(edges);
    });
};