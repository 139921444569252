<script>
    import { onMount, onDestroy } from 'svelte';
    //  need to rename canvas because otherwise the linter incorrectly nags about:
    //      "`<canvas>` will be treated as an HTML element unless it begins with a capital letter [plugin esbuild-svelte]"
    // 
    import { canvas as canvasStore, viewWidth, viewHeight } from '../stores/renderer';

    let container;
    const observer = new ResizeObserver(entries => {
        for (let entry of entries) {
            const cr = entry.contentRect;
            $viewWidth = cr.width;
            $viewHeight = cr.height;
        }
    });

    onMount(() => {
        observer.observe(container);
    });

    onDestroy(() => {
        observer.disconnect();
    });
</script>

<style>
    div {
        position: absolute;
        width: 100%;
        height: 100%;
    }
</style>

<div bind:this={container}>
    <canvas bind:this={$canvasStore}></canvas>
    {#if $canvasStore}
        <slot />
    {/if}
</div>