import { writable } from 'svelte/store';

const viewWidth = writable(0);
const viewHeight = writable(0);

const canvas = writable(null);
const renderer = writable(null);
const camera = writable(null);
const scene = writable(null);
const controls = writable(null);
const lighting = writable(null);
const raycaster = writable(null);
const mouse = writable({
    x: 0,
    y: 0
});
const center = writable({
    x: 0,
    y: 0
});
const zoom = writable(1);
const bounds = writable({
    x0: 0, y0: 0, 
    x1: 0, y1: 0,
    x2: 0, y2: 0,
    x3: 0, y3: 0
});
const terrain = writable(null);

const settings = {
    antiAlias: writable(true),
    pixelRatio: writable(devicePixelRatio),
    forceWireframe: writable(false),
    showGrid: writable(false),
}

export {
    viewWidth, viewHeight,
    canvas, renderer, camera, scene, controls, lighting,
    raycaster, center, zoom, mouse, bounds,
    terrain,

    settings
}